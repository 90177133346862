import { css } from '@emotion/react'
import React, { forwardRef, InputHTMLAttributes, useCallback, useState } from 'react'
import { Icon } from 'components/icon'

export const PasswordInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, ref) => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const visibilityRef = React.useRef<HTMLSpanElement>(null)

  const togglePasswordVisibility = useCallback(() => {
    setPasswordShown((value) => !value)
  }, [setPasswordShown])

  React.useEffect(() => {
    const element = visibilityRef.current
    element?.addEventListener?.('click', togglePasswordVisibility)

    return () => {
      element?.removeEventListener?.('click', togglePasswordVisibility)
    }
  }, [visibilityRef, togglePasswordVisibility])

  return (
    <>
      <input
        ref={ref}
        autoFocus
        {...props}
        type={passwordShown ? 'text' : 'password'}
        css={css`
          &::-webkit-credentials-auto-fill-button {
            position: absolute;
            right: 42px;
            transform: translateY(-50%);
          }
        `}
      />
      <legend
        css={css`
          position: absolute;
          height: 24px;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--c-gray500);
          cursor: pointer;
        `}
      >
        <span ref={visibilityRef}>{<Icon name={passwordShown ? 'EyeClosed' : 'EyeOpened'} />}</span>
      </legend>
    </>
  )
})
PasswordInput.displayName = 'PasswordInput'

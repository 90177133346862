import styled from '@emotion/styled'
import { BREAKPOINTS, StyledLinkProps } from 'utils/styled'

export const SAuthContainer = styled.div<StyledLinkProps>`
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media ${BREAKPOINTS.MD.max} {
    margin: 0;
  }
`

export const SSubscriptionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
`

import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const ColumnStack = styled.div<{ breakpoint?: keyof typeof BREAKPOINTS; space?: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${({ space }) => pxToRem(space || 120)}rem;
  margin-bottom: ${pxToRem(60)}rem;

  @media ${({ breakpoint }) => BREAKPOINTS[breakpoint ? breakpoint : 'XL'].max} {
    grid-template-columns: 1fr;
  }

  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: ${pxToRem(40)}rem;
  }
`

export const ColumnBlock = styled.div`
  break-inside: avoid;

  & fieldset,
  & .wizard-card-field {
    transform: translate3d(0, 0, 1px);

    @media ${BREAKPOINTS.XL.max} {
      transform: none;
    }
  }
`

import styled from '@emotion/styled'
import { Image } from 'components/Image'
import { BREAKPOINTS } from 'utils/styled'

export const SWrapper = styled.div`
  max-width: 27.5rem;
  padding: 2rem;
  margin: 0 auto;
  box-shadow: var(--card-shadow);

  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${BREAKPOINTS.MD.max} {
    max-width: 100%;
  }
`

export const SImage = styled(Image)`
  height: 4rem;
  max-width: 8rem;
  margin: 1rem 0;
  object-fit: contain;
`

export const SDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--c-blue100);
  margin: 1rem 0;
`

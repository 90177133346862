import { Perk } from 'components/perkItem/PerkItem'
import { PerksList } from 'components/perksList/PerksList'
import { SDivider, SImage, SWrapper } from './PartnerPromotionCard.styled'
import { Text, Title } from 'components/Typography'
import { useTranslation } from 'next-i18next'

export const PartnerPromotionCard = (props: {
  partnerLogoSrc?: string
  title: string
  perks?: Perk[]
}) => {
  const { t } = useTranslation('auth')
  return (
    <SWrapper>
      <Text variant="bodySmall">{t('auth.lead_account.about.promo_card_text')}</Text>

      <SImage src={props.partnerLogoSrc} />

      <Title variant="h7">{props.title}</Title>

      <SDivider />

      <PerksList perks={props.perks} />
    </SWrapper>
  )
}

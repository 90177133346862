import { deleteUserSocialBinding, postUserSocialBinding, SocialSite } from 'api/goodtrust/user'
import {
  ErrorMessage,
  handleAndToastError,
  toastError,
  toastErrorString,
  toastSuccess,
} from 'components/Toast'
import { GoogleLoginProps, GoogleLoginResponse } from 'react-google-login'
import { MessageException } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { logError } from 'utils/general'
import { SuccessResponse } from '@greatsumini/react-facebook-login'

export interface SocialProps {
  site: SocialSite
  unbind?: boolean
  accessToken?: string
  error?: string
  details?: string
}

export interface IFacebookRenderProps {
  isDisabled: boolean
  isProcessing: boolean
  isSdkLoaded: boolean
  onClick: () => void
}

export interface FacebookLoginResponse {
  accessToken: string
  expiresIn: string
  signedRequest: string
  userID: string
}

export interface IFacebookCallback {
  (response: SuccessResponse): Promise<unknown>
}

export type GoogleSuccessResponse = Parameters<NonNullable<GoogleLoginProps['onSuccess']>>[0]

export function isGoogleLoginResponse(
  response: GoogleSuccessResponse
): response is GoogleLoginResponse {
  if ('accessToken' in response) return true
  return false
}

export interface IGoogleSuccessCallback {
  (response: GoogleLoginResponse): Promise<unknown>
}

export interface IGoogleFailureCallback {
  (response: { details: string; error: string }): void
}

export interface IAuthSocialProps {
  onFacebook: IFacebookCallback
  onGoogleSuccess: IGoogleSuccessCallback
  onGoogleFailure: IGoogleFailureCallback
  block?: boolean
  areButtonsSignup?: boolean
}

export const FacebookScope = 'public_profile,email,user_birthday,user_gender'
export const FacebookFields = 'name,email,picture,birthday,gender'
export const GoogleScope =
  'https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.emails.read'

export const onSocialProcess = async ({
  site,
  unbind,
  accessToken,
  error,
  details,
}: SocialProps) => {
  try {
    if (unbind) {
      await deleteUserSocialBinding(site).then(unwrapResponse)
    } else {
      if (!accessToken || error || details) {
        if (error !== 'popup_closed_by_user') {
          if (details) {
            throw Error(details)
          } else {
            throw new MessageException('failed_to_connect_account')
          }
        }
        return
      }
      await postUserSocialBinding(accessToken, site).then(unwrapResponse)
    }
    toastSuccess(unbind ? 'account_unbinded' : 'account_binded')
  } catch (err) {
    handleAndToastError(err)
  }
}

export const onGoogleFailureToastError = (
  response: { details?: string; error: string },
  customFallbackKey?: ErrorMessage
): void => {
  let code: ErrorMessage | undefined
  if (response.error === 'idpiframe_initialization_failed') {
    code = 'google_login_needs_cookies'
  }

  // show toast message
  const shouldShowToast = response.error !== 'popup_closed_by_user'
  if (shouldShowToast) {
    if (code) {
      toastError(code)
    } else if (response.details) {
      toastErrorString(response.details)
    } else {
      toastError(customFallbackKey ?? 'failed_to_continue_google')
    }
  }

  // log error
  const ignoredErrors = ['popup_closed_by_user', 'idpiframe_initialization_failed']
  const shouldLogError = !ignoredErrors.includes(response.error)

  if (shouldLogError) {
    logError('Google failure error ' + response.error)
  }
}

export const isFacebookMessengerAvailable = () => {
  // we can change this to true to make messenger available
  return false
}

import { ApiType } from 'utils/types'
import { apiAuthFetcher } from './api'
import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'

export function useLeadAccount(uuid?: string) {
  return useSWR<FetchResponse<ApiType['LeadAccountResponse']>>(
    uuid ? `/api/v1/lead-account/${uuid}` : null,
    apiAuthFetcher
  )
}

import { css } from '@emotion/react'
import { postUserVerification } from 'api/goodtrust/auth'
import { useLeadAccount } from 'api/goodtrust/leadAccount'
import { patchUserMe, SocialSite, useUserMe } from 'api/goodtrust/user'
import { SAuthContainer } from 'components/auth/AuthContainer'
import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { ColumnBlock, ColumnStack } from 'components/Column'
import { Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { InputGrid } from 'components/Grid'
import { HeaderDashboard } from 'components/header/headerDashboard/HeaderDashboard'
import { Icon } from 'components/icon'
import { Layout } from 'components/layout/Layout'
import { PageTitle } from 'components/PageTitle'
import { PasswordInput } from 'components/passwordInput/PasswordInput'
import { SafeForm } from 'components/SafeForm'
import { Spacer } from 'components/spacer/Spacer'
import { BlockSpinner } from 'components/spinner/Spinner'
import { Stack } from 'components/Stack'
import { PartnerPromotionCard } from 'components/subscription/cards/PartnerPromotionCard'
import { handleAndToastError } from 'components/Toast'
import { Title, Title_h3_default } from 'components/Typography'
import { WizardSubtitle } from 'components/wizard/WizardTypography'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { getPerks } from 'logic/subscription/plan/spec'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import Router, { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import FacebookLogin from '@greatsumini/react-facebook-login'
import GoogleLogin from 'react-google-login'
import { useForm } from 'react-hook-form'
import { hashPassword } from 'utils/auth/password'
import { encodeQuery, unwrapResponse } from 'utils/fetcher'
import { useRunOnceReady, useWindowSize } from 'utils/hooks'
import { getIntlProps } from 'utils/i18n/getIntlProps'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import {
  FacebookFields,
  FacebookScope,
  GoogleScope,
  onSocialProcess,
  SocialProps,
} from 'utils/social'

export interface FormFields {
  first_name: string
  last_name: string
  email: string
  password: string
}

export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string

export default function Page() {
  const { t } = useTranslation('pricing_plan')
  const { t: tAuth } = useTranslation('auth')
  const validation = useFieldValidation()
  const router = useRouter()
  const uuid = router.query.uuid as string
  const pass = router.query.pass as string

  const form = useForm<FormFields>()

  const leadAccountSWR = useLeadAccount(uuid)
  const leadAccount = useMemo(() => leadAccountSWR.data?.json, [leadAccountSWR])
  const perks = getPerks(t, 'ESTATE_PLAN')

  const { isDesktop } = useWindowSize()
  const { data, mutate } = useUserMe()
  const facebookEnabled = data?.json?.provider?.includes('FACEBOOK')
  const googleEnabled = data?.json?.provider?.includes('GOOGLE')
  const [processedSocialSite, setProcessedSocialSite] = useState<SocialSite>()

  useRunOnceReady(!!leadAccount, () => {
    form.setValue('first_name', leadAccount?.first_name || '')
    form.setValue('last_name', leadAccount?.last_name || '')
    form.setValue('email', leadAccount?.email || '')
  })

  const handleSubmit = async (payload: FormFields) => {
    try {
      const res = await postUserVerification({
        password: await hashPassword(pass),
      }).then(unwrapResponse)

      await patchUserMe({
        first_name: payload.first_name,
        last_name: payload.last_name,
        password: await hashPassword(payload.password),
        verification_token: res.json?.verification_token,
      }).then(unwrapResponse)

      await Router.push(encodeQuery('/signup/lead-account/mfa'))
    } catch (err) {
      handleAndToastError(err, 'failed_to_save_profile')
    }
  }

  const onSocial = async (props: SocialProps) => {
    setProcessedSocialSite(props.site)
    await onSocialProcess(props)
    await mutate()
    setProcessedSocialSite(undefined)
  }

  if (!leadAccount) return <BlockSpinner />

  const offer = leadAccount.lead_offer_response?.promo_codes
    ?.map((promo) => {
      const freeSubscriptionOffer = promo.offers?.find((offer) =>
        describePromoOffer(offer).asFreeSubscription()
      )
      return {
        freeSubscriptionOffer,
        promo,
      }
    })
    .find((x) => x.freeSubscriptionOffer != null)

  return (
    <Layout header={<HeaderDashboard />}>
      <PageTitle title={tAuth('auth.lead_account.about.meta_title')} />

      <Block>
        <SAuthContainer>
          {data?.json ? (
            <div>
              <ColumnStack space={22} breakpoint="LG">
                <ColumnBlock>
                  <Title
                    variant={Title_h3_default}
                    as="h3"
                    css={css`
                      max-width: 60rem;
                      margin-bottom: 2.25rem;
                      overflow: hidden;
                      overflow-wrap: break-word;
                    `}
                  >
                    {tAuth('auth.lead_account.about.title', {
                      who: leadAccount.first_name || leadAccount.email,
                    })}
                  </Title>

                  <WizardSubtitle>{tAuth('auth.lead_account.about.subtitle')}</WizardSubtitle>
                  <Stack space="0.75rem" orientation={isDesktop ? 'horizontal' : 'vertical'}>
                    <FacebookLogin
                      appId={FACEBOOK_APP_ID}
                      onSuccess={(userInfo) => onSocial({ ...userInfo, site: 'facebook' })}
                      scope={FacebookScope}
                      fields={FacebookFields}
                      render={(renderProps) => (
                        <Button
                          loading={processedSocialSite === 'facebook'}
                          variant="secondary"
                          size="large"
                          tone="dark"
                          type="button"
                          leftIcon={<Icon name="ColorFacebook" />}
                          stickyIcon
                          block
                          disabled={facebookEnabled}
                          onClick={() => renderProps.onClick?.()}
                        >
                          {facebookEnabled
                            ? tAuth('auth.contact.about_you.fb_connected')
                            : tAuth('auth.contact.about_you.connect_fb_button')}
                        </Button>
                      )}
                    />
                    <GoogleLogin
                      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
                      render={(renderProps) => (
                        <Button
                          loading={processedSocialSite === 'google'}
                          variant="secondary"
                          size="large"
                          tone="dark"
                          type="button"
                          leftIcon={<Icon name="ColorGoogle" />}
                          stickyIcon
                          block
                          onClick={() => renderProps.onClick()}
                        >
                          {googleEnabled
                            ? tAuth('auth.contact.about_you.google_connected')
                            : tAuth('auth.contact.about_you.connect_google_button')}
                        </Button>
                      )}
                      buttonText={tAuth('auth.contact.about_you.login_button')}
                      onSuccess={(e) => onSocial({ ...e, site: 'google' })}
                      onFailure={(e) => onSocial({ ...e, site: 'google' })}
                      cookiePolicy={'single_host_origin'}
                      scope={GoogleScope}
                    />
                  </Stack>

                  <Spacer size={1.5} />

                  <WizardSubtitle>{tAuth('auth.contact.about_you.form_more_title')}</WizardSubtitle>
                  <SafeForm formState={form.formState} onSubmit={form.handleSubmit(handleSubmit)}>
                    <InputGrid>
                      <FormFieldset form={form}>
                        <Fieldset>
                          <input
                            {...form.register('first_name', validation.required())}
                            type="text"
                            placeholder="&nbsp;"
                          />
                          <label>{tAuth('auth.contact.about_you.first_name_label')}</label>
                        </Fieldset>
                      </FormFieldset>
                      <FormFieldset form={form}>
                        <Fieldset>
                          <input
                            {...form.register('last_name', validation.required())}
                            type="text"
                            placeholder="&nbsp;"
                          />
                          <label>{tAuth('auth.contact.about_you.last_name_label')}</label>
                        </Fieldset>
                      </FormFieldset>

                      <FormFieldset form={form}>
                        <Fieldset css={{ gridColumnEnd: 'span 2' }}>
                          <input
                            {...form.register('email')}
                            type="email"
                            placeholder="&nbsp;"
                            disabled
                          />
                        </Fieldset>{' '}
                        <label>{tAuth('auth.contact.about_you.email_label')}</label>
                      </FormFieldset>

                      <FormFieldset form={form}>
                        <Fieldset css={{ gridColumnEnd: 'span 2' }}>
                          <PasswordInput
                            {...form.register('password', validation.required())}
                            autoComplete="new-password"
                            placeholder="&nbsp;"
                          />
                          <label>{tAuth('auth.contact.about_you.password_label')}</label>
                        </Fieldset>
                      </FormFieldset>

                      <Button
                        type="submit"
                        size="large"
                        loading={form.formState.isSubmitting}
                        css={{ marginTop: '2rem' }}
                      >
                        {tAuth('auth.contact.about_you.save_button')}
                      </Button>
                    </InputGrid>
                  </SafeForm>
                </ColumnBlock>

                <ColumnBlock>
                  <PartnerPromotionCard
                    title={
                      offer?.freeSubscriptionOffer?.amount === -1
                        ? tAuth('auth.lead_account.about.promo_card_title_lifetime')
                        : offer?.freeSubscriptionOffer?.amount === 1
                        ? tAuth('auth.lead_account.about.promo_card_title_singular', {
                            amount: offer?.freeSubscriptionOffer?.amount,
                          })
                        : tAuth('auth.lead_account.about.promo_card_title_plural', {
                            amount: offer?.freeSubscriptionOffer?.amount,
                          })
                    }
                    partnerLogoSrc={leadAccount.lead_offer_response?.partner?.partner_photo?.url}
                    perks={perks}
                  />
                </ColumnBlock>
              </ColumnStack>
            </div>
          ) : (
            <BlockSpinner />
          )}
        </SAuthContainer>
      </Block>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  return { props: { ...(await getIntlProps(ctx, ['pricing_plan', 'auth'])) } }
}
